<template>
  <b-overlay :show="loading" class="row">
    <div class="col-12 col-lg-6 c g">
      <div class="card">
        <div class="card-header">
          <h4 class="text-center">
            <i class="fa fa-user-plus"></i>
            {{ e("edit-user") }}
          </h4>
        </div>
        <div class="card-body">
          <div class="form-group">
            <h5 for="">{{ e("name") }}</h5>
            <input
              type="text"
              class="form-control form-control-lg"
              v-model="member.name"
              :placeholder="e('write-here')"
            />
          </div>
          <div class="form-group">
            <h5 for="">{{ e("number") }}</h5>
            <input
              type="text"
              class="form-control form-control-lg"
              v-model="member.number"
              :placeholder="e('write-here')"
            />
          </div>
          <div class="form-group">
            <h5 for="">{{ e("username") }}</h5>
            <input
              type="text"
              class="form-control form-control-lg"
              v-model="member.username"
              :placeholder="e('write-here')"
            />
          </div>
          <div class="form-group">
            <h5 for="">{{ e("password") }}</h5>
            <input
              type="text"
              class="form-control form-control-lg"
              v-model="member.password"
              :placeholder="e('write-here')"
            />
          </div>
          <div class="form-group">
            <h5 for="">{{ e("phone") }}</h5>
            <input
              type="text"
              class="form-control form-control-lg"
              v-model="member.phone"
              :placeholder="e('write-here')"
            />
          </div>
          <div class="form-group">
            <h5 for="">{{ e("notes") }}</h5>
            <textarea
              type="text"
              class="form-control form-control-lg"
              v-model="member.notes"
              :placeholder="e('write-here')"
            ></textarea>
          </div>
          <div class="form-group">
            <h5 for="">{{ e("settings-group") }}</h5>
            <select
              class="form-control form-control-lg"
              v-model="member.settings_group_id"
            >
              <template v-for="group in groups">
                <option :key="group._id" :value="group._id">
                  {{ group.title }}
                </option>
              </template>
            </select>
          </div>
          <div class="form-group">
            <h5 for="">{{ e("salary") }}</h5>
            <input
              type="number"
              class="form-control form-control-lg"
              v-model="member.salary"
              :placeholder="e('write-here')"
            />
          </div>
          <!-- switch -->
          <div class="demo-inline-spacing">
            <b-form-checkbox
              class="custom-control-success"
              name="check-button"
              v-model="member.allow_location_app"
              :value="true"
              switch
              inline
            >
              <strong> {{ e("allow-location-app") }}</strong>
            </b-form-checkbox>
          </div>
          <div v-if="user.branches">
            <!-- switch -->
            <div class="demo-inline-spacing">
              <b-form-checkbox
                class="custom-control-success"
                name="check-button"
                v-model="member.all_branches"
                :value="true"
                switch
                inline
              >
                <strong> {{ e("allow-all-branches") }}</strong>
              </b-form-checkbox>
            </div>
            <div v-if="!member.all_branches" class="border g">
              <div
                class="demo-inline-spacing"
                v-for="branche in user.branches_list"
                :key="branche.id"
              >
                <b-form-checkbox
                  class="custom-control-primary"
                  name="check-button"
                  v-model="branches"
                  :value="branche.id"
                  switch
                  inline
                >
                  <strong> {{ branche.title }}</strong>
                </b-form-checkbox>
              </div>
            </div>
          </div>
          <div class="col-12 text-center g">
            <button class="btn btn-relief-success btn-lg" @click="addNow()">
              {{ e("edit-user") }}
              <i class="fa fa-arrow-left"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { BLink, BOverlay, BFormCheckbox } from "bootstrap-vue";
export default {
  components: {
    BLink,
    BOverlay,
    BFormCheckbox,
  },
  data() {
    return {
      loading: true,
      user: JSON.parse(localStorage.getItem("user")),
      groups: [],
      branches: [],
      member: {
        all_branches: true,
      },
    };
  },
  created() {
    var g = this;
    $.post(api + "/general/settings-groups/index", {
      jwt: g.user.jwt,
    })
      .then(function (r) {
        g.groups = JSON.parse(r);
        $.post(api + "/users/user", {
          jwt: g.user.jwt,
          id: window.location.pathname.split("edit/")[1],
        })
          .then(function (r) {
            g.member = JSON.parse(r);
            g.loading = false;
            if (g.member.branches) {
              g.branches = g.member.branches;
            }
          })
          .catch(function (e) {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: "Error: " + e,
                icon: "TimesIcon",
                variant: "danger",
              },
            });
          });
      })
      .catch(function (e) {
        g.$toast({
          component: ToastificationContent,
          props: {
            title: "Error: " + e,
            icon: "TimesIcon",
            variant: "danger",
          },
        });
      });
  },
  methods: {
    e(d) {
      return e(d);
    },
    addNow() {
      var _g = this;
      _g.member.branches = _g.branches;
      _g.loading = true;
      _g.member._id = window.location.pathname.split("edit/")[1];
      $.post(api + "/users/edit", {
        jwt: _g.user.jwt,
        member: this.member,
      })
        .then(function (response) {
          response = JSON.parse(response);
          if (response.status == 100) {
            _g.$toast({
              component: ToastificationContent,
              props: {
                title: e("user-edited"),
                icon: "CheckIcon",
                variant: "success",
              },
            });
          } else {
            _g.$toast({
              component: ToastificationContent,
              props: {
                title: e(response.response),
                icon: "TimesIcon",
                variant: "danger",
              },
            });
          }
          _g.loading = false;
        })
        .catch(function () {
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: e("error"),
              icon: "TimesIcon",
              variant: "danger",
            },
          });
          _g.loading = false;
        });
    },
  },
};
</script>